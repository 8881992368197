export const envConfigForShare = {
  prod: {
    policyServiceUrl: 'https://health-policy-service.internal.live.acko.com/health/policy-details',
  },
  master: {
    policyServiceUrl: 'https://health-policy-service-uat.internal.ackodev.com/health/policy-details',
  },
  dev: {
    policyServiceUrl: 'https://health-policy-service-uat.internal.ackodev.com/health/policy-details',
  },
  local: {
    policyServiceUrl: 'https://health-policy-service-uat.internal.ackodev.com/health/policy-details',
  },
}
